* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root, .app {
  height: 100%;
  margin: 0;
  background: radial-gradient(ellipse at bottom, #091f1c 0%, #0d161a 50%);
}

.app {
  text-align: center;
  background-color: rgb(21,23,25);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  width: 100%; /* Set width before max-width */
  max-width: 80%; 
  margin: 0 auto; /* Center the app */
}

.app-header h1 {
  text-align: center;

  /* Enable gradient background on text */
  background-clip: text;
  color: transparent; /* Make the text color transparent so the background is visible */
  background-image: linear-gradient(90deg, #e76f51, #f4a261, #2a9d8f, #264653);
  background-size: 200% 100%;
  animation: gradient-shift 6s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Define the keyframes for the gradient shift */
@keyframes gradient-shift {
  0%, 100% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
}

.minting-section {
  padding: 2rem 0;
  text-align: center;
  /* max-width: 80%;  */
}

.collection-section {
  padding: 2rem 0;
}

h1 {
  font-size: 4em; /* Large text for the main title */
}

h2 {
  font-size: 2em; /* Subsection titles */
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.form-container {
  margin-bottom: 20px;
}

.text-input {
  padding: 10px;
  font-size: 1em;
  margin-right: 10px; /* Space between the input and the button */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #28a745; /* Green color */
  color: white;
  transition: background-color 0.3s;
}

.submit-button:hover {
  opacity: 0.8;
}

.image-container {
  flex: 1; /* Take up remaining space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
}

.main-content {
  width: 100%;
  max-width: 1200px; /* Adjust the max-width as needed */
  margin: 0 auto; /* This centers the main content */
  padding: 0 20px; /* Add some padding on the sides */
}

.masterlist-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1em;
  padding: 1em;
  margin-top: 1em;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.masterlist-item {
  position: relative; /* Needed to position the overlay correctly */
  /* ... other styles ... */
}

.masterlist-item:hover {
  border: 2px solid darkgray; /* Highlight the cell on hover */
  border-radius: 3px;
  background-color: rgba(218, 216, 216, 0.2);
}

.buttons-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.overlay-button {
  padding: 5px 10px;
  background-color: green;
  color: white;
  text-align: center;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 0.8em;
}

.overlay-button:hover {
  background-color: darkgreen;
}

.masterlist-item p {
  max-width: 100%; /* Set the maximum width of the text. */
  text-overflow: ellipsis; /* Use ellipsis for overflowed text. */
  overflow: hidden; /* Hide the overflow. */
  white-space: nowrap; /* Prevent text from wrapping to the next line. */
}

.pixel-art-image {
  image-rendering: pixelated; /* This will give a blocky upscaling */
  width: 100%; /* You can adjust this for the size you want */
  height: auto;
}

.banner-image {
  max-width: 800px; /* Set the maximum width to 800px */
  width: 100%; /* Make it responsive: it will take up 100% of the container width until it reaches 800px */
  height: auto; /* Maintain the aspect ratio of the image */
}

.splash-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em;
}

.fren-item {
  text-align: center;
  margin-top: 1rem;
}

.fren-item img {
  max-width: 100%;
  image-rendering: pixelated;
}

.blob-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 1em;
}

.blob-text {
  background-color: #1a1a1a;
  padding: 10px;
  border-radius: 5px;
  max-width: 500px;
  word-wrap: break-word;
  white-space: normal;
}

.copy-button {
  padding: 5px 10px;
  font-size: 0.8em;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
}


.instructions {
  text-align: left;
  margin-top: 20px;
  max-width: 500px;
}

.subheader-section {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

.subheader-section h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.ninja-collection-link {
  position: fixed;
  right: 20px; /* Distance from right */
  bottom: 20px; /* Distance from bottom */
  background-color: #264653; /* Example background color */
  color: white; /* Text color */
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none; /* Remove underline from link */
  font-size: 0.9em;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Optional: adds shadow for better visibility */
  z-index: 1000; /* Ensure it's above other content */
}

.ninja-collection-link:hover {
  background-color: #2a9d8f; /* Color change on hover */
}

